/* 

.btn-solid1 {
    transform: perspective(1px) translateZ(0) skewX(-8deg);
    box-shadow: 0 0 1px transparent;
    border: 2.5px solid;
    border-color: #283845;
    font-weight: 510;
    letter-spacing: .07em;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 14px;
    }
    .btn1 {
      display: inline-block;
      vertical-align: middle;
      transition: .5s ease-out;
      text-decoration: none;
      cursor: pointer;
    }
   */
    .container1 {
        position: absolute;
        left: 0;
        height: 110%;
        width: 110%;
       
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3rem;
        padding: 0 2rem;
        overflow: hidden;
        cursor: pointer;
    }
    .loading-bar {
    position: absolute;
    top: calc(100% - 3px);
    left: 0;
    width: 0%;
    height: 3px;
    background: white;
}
.truckBg {
    background: url("../assets/images/eztruck.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.menu {
    background: url("../assets/carousel/6.JPG");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 1;
    scale: 1;
}

.menu1 {
    background: url("../assets/carousel/2.JPG");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    opacity: 1;
    scale: 1;
}