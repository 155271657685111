.gradient {
    background: linear-gradient(#07252d, 8%, #32cd32); 
}
.gradient2 {
    background: linear-gradient( #32cd32, 85%, #07252d );
}
.gradient3 {
  background: linear-gradient(#07252d, 8%, #32cd32); 
}
.bg-deco {
    background-image: url("../../assets/images/bgBlue.png");
    background-position: fixed;
}
.bg-deco2 {
  background-image: url("../../assets/images/bgGreen.png");
  background-position: fixed;
}
.section1-img {
    background: url("../../assets/carousel/4.JPG");
    background-size: cover;
    background-position: fixed !important;
}
.bg-contact {
    background: url("../../assets/carousel/2.JPG");
    background-position: fixed;
    background-size: 70% ;
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
    
}
.bg-contactMobile {
  background: url("../../assets/carousel/2.JPG");
  background-position: fixed;
  background-size: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.contactImg2 {
    z-index: 1 !important;
}
.contactText {
    z-index: 2 !important;
}
.truck {
  animation: spin 65s linear infinite; 
}

@keyframes spin{
  from{transform: rotate(0deg);}
  to{transform: rotate(360deg);}
}
/* .img4 {
    max-width: 700px;
    z-index: 1;
    vertical-align: middle;
  }
  .scroll-container {
    justify-content: space-between;
    max-width: 1200px;
    /* This is needed to prevent the image from "jittering" partway through the transition */
    /* overflow: hidden; */
  /* } */ 
  /* .bottom-shadow {
    background: linear-gradient(90deg, transparent 0%, rgba(2, 48, 71, 1) 20%);
    z-index: 0;
  }
  
  .top-shadow {
    background: linear-gradient(90deg, transparent -1%, rgba(2, 48, 71, 1) 20%);
    z-index: 2;
  }
  .head {
    text-shadow: 2px, 4px, 4px, black;
  }
  */